.form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8% 0;
}

/* ---- tracker ---- */

.form_tracker {
  display: flex;
  width: 50%;
  justify-content: space-around;
  align-items: center;

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgb(76, 109, 124);
  }

  p {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: grey;
    opacity: 0.2;
  }
}


/* ----------- */

.form_main {
  width: 50%;

  h2 {
    text-align: center;
  }
}


.has-float-label {
  font-size: 75%;
  margin: 4% 2% 1% 2%;
}

.input_field {
  width: 100%;
}

.floating_placeholder {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 18px;
  transition: 0.2s ease all;
  opacity: 0.7;
}

.input_field:focus ~ .floating_placeholder,
.input_field:not(:focus):valid .floating_placeholder {
  top: 8px;
  bottom: 10px;
  left: 20px;
  font-size: 1px;
  opacity: 1;
}

/* -------- */

.form_btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5%;
  height: 100%;

  img {
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(0.98);
    }
  }
}

.submit_btn {
  cursor: pointer;
}

.submit_btn:hover {
  transform: scale(1.05);
}

.submit_btn:active {
  transform: scale(0.98);
}

.validation {
  color: red;
  text-align: center;
}

@media (max-width: 1100px) {
  .form_main {
    width: 90vw;
  }
}

@media (max-width: 800px) {
  .form_tracker {
    margin-top: 5vh;
  }

  .form_container h1 {
    font-size: 1.5em;
  }

  .form_main h2 {
    font-size: 1em;
  }

  .has-float-label input:placeholder-shown:not(:focus) + *,
  .has-float-label select:placeholder-shown:not(:focus) + *,
  .has-float-label textarea:placeholder-shown:not(:focus) + * #ce_title {
    font-size: 120%;
  }
}
