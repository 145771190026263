.scroll {
    opacity: 0;
}

.fader {
    animation: fader 1s;
    // width: 100%;
}

button:focus {
    outline: none;
}

@keyframes fader {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}