.home_img {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-image: url(https://res.cloudinary.com/dwvrok1le/image/upload/v1547432341/deron.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
  z-index: -1;
  animation: fadeInOut 1s;
}

.home_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.home_sub {
  background-image: url('./dust_scratches.png');
  margin-bottom: -60px;
  display: flex;
  justify-content: space-around;
  padding-top: 2%;
}

.home_sub h3 {
  color: rgb(146, 125, 125);
}

.article_link {
  text-decoration: none;
}

.home_article p {
  color: rgb(82, 82, 82);
}

.home_article p:hover,
.cont_email p:hover {
  opacity: 0.8;
}

.home_article p:active,
.cont_email p:active {
  transform: scale(0.995);
}

.home_container h1 {
  font-family: 'Lato', sans-serif;
  font-size: 1.4rem;
  font-weight: 200;
  letter-spacing: 2px;
  color: #fff;
  animation: fadeInOutText 2s;
}

.contact_holder {
  height: 175px;
}

.contact_holder h3 {
  text-align: center;
}

.contact_email {
  text-decoration: none;
  letter-spacing: 2px;
  color: #000;
  cursor: pointer;
}

.cont_email p:hover {
  opacity: 0.8;
}

.ch1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_holder p {
  text-align: center;
  font-size: 0.9rem;
  letter-spacing: 2px;
  color: rgb(82, 82, 82);
}

.social_media {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5%;
}

.social_media > div {
  display: flex;
  justify-content: space-around;
  margin: 2% 0;
}

.social_icon {
  /* height: 35px;
  width: 40px; */
}

.social_icon:hover {
  transform: scale(1.02);
}

.twitter {
  border-radius: 50%;
}

.home_sub_2 {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.home_calendar {
  width: 50%;
  margin-bottom: 70px;
}

.home_sub12 {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 80px;
  align-items: center;
}

.home_image {
  margin-top: 5%;
  align-self: center;
  width: 80%;
}

.home_article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  border-bottom: 1px solid rgb(197, 197, 197);
  padding-bottom: 2%;
  margin: 0 10%;
}

/* Arrow */

.scroll_to_top {
  position: fixed;
  left: 50%;
  transform: translate(-50%) rotate(180deg);
  bottom: 15px;
  width: 25px;
  height: 25px;
  background-image: url('https://image.flaticon.com/icons/svg/118/118738.svg');
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  cursor: pointer;
  animation: bounce 2.5s infinite;
}

.scroll_to_top:hover {
  opacity: 0.3;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.9;
  }
}

@keyframes fadeInOutText {
  0% {
    opacity: 0;
    transform: translate(-10px);
  }

  40% {
    opacity: 0;
    transform: translate(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@media (max-width: 1100px) {
  .home_sub_2 {
    flex-direction: column;
  }

  .upcoming_events {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .home_sub {
    flex-direction: column-reverse;
    align-items: center;
  }

  .upcoming_events {
    width: 90%;
  }

  .home_sub12,
  .home_article {
    width: 95%;
  }

  .home_sub12 {
    margin-top: -8%;
  }
  .home_sub_2 {
    flex-direction: row;
    width: 90%;
    margin-bottom: 70px;
  }

  .contact_holder {
    width: 50%;
  }

  .home_calendar {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .article_wrapper {
    margin: 0 10%;
  }
}

@media (max-width: 500px) {
  .home_sub_2 {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
  }

  .contact_holder {
    width: 95%;
  }

  .ue_2 p {
    font-size: 0.7rem;
  }
}

@media (max-width: 450px) {
  .article_top_map h2 {
    font-size: 0.8rem;
    width: 100%;
  }
}
