body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: hsla(0, 0%, 0%, 0.8);

}

.scroll {
  opacity: 0; }

.fader {
  -webkit-animation: fader 1s;
          animation: fader 1s; }

button:focus {
  outline: none; }

@-webkit-keyframes fader {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fader {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.verse {
    text-align: center;
    margin: 5% 5% 2% 5%;
    width: 90%;
}

@media (max-width: 550px) {
    .verse {
        font-size: .8rem;
    }
}
.bio_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8%;
  width: 100%;
  opacity: 1;
  background: #fdfdfd; }

.bio_name_sub {
  display: flex;
  justify-content: center;
  align-items: center; }

.bio_sub p {
  text-align: center;
  font-size: 0.9rem;
  letter-spacing: 2px; }

.about_name {
  text-align: center;
  height: 100%;
  font-size: 3.5rem;
  letter-spacing: 2px;
  color: #3c3c3c; }

.about_container {
  background: #fdfdfd;
  padding: 5% 10% 0 10%;
  margin-bottom: 2%;
  opacity: 1; }
  .about_container p {
    font-size: 1rem; }

.profile_pic {
  float: left;
  margin: 1% 2%;
  height: 400px; }

.about_ed {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 5px #d8d8d8;
  padding: 1%; }

.about_ed > div:nth-of-type(2) {
  height: 300px;
  display: flex;
  flex-direction: row; }

.about_ed_sub1 {
  display: flex;
  letter-spacing: 2px; }

.btm_line_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  width: 100%; }

.btm_a_line {
  width: 20px;
  border: 1px solid #adadad;
  background: #adadad;
  margin: 5%; }

.education1 {
  position: relative; }

.education_img {
  width: 100px;
  position: absolute;
  z-index: -1;
  opacity: 0.3;
  top: 2%; }

.img1 {
  left: 2%; }

.img2 {
  right: 2%; }

.education_title {
  margin-top: 3.5%; }

.about_ed p {
  font-size: 0.9rem; }

.line_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2%; }

.a_line {
  width: 40px;
  border: 1px solid #adadad;
  background: #adadad;
  margin: 5%; }

.about_bottom {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center; }

#slideshow {
  position: relative;
  width: 400px;
  height: 500px;
  padding: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4); }
  #slideshow > div {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px; }

.image_scroll {
  max-height: 100%;
  max-width: 100%;
  margin: auto; }

.image_scroll2 {
  max-height: 100%;
  max-width: 100%;
  margin: auto; }

@-webkit-keyframes fadeInOutAbout {
  0% {
    opacity: 0.1; }
  100% {
    opacity: 0.3; } }

@keyframes fadeInOutAbout {
  0% {
    opacity: 0.1; }
  100% {
    opacity: 0.3; } }

@media (max-width: 1100px) {
  .about_bottom {
    flex-direction: column; }
  .about_ed {
    margin: 2% 0; } }

@media (max-width: 800px) {
  .bio_name_sub {
    margin-top: 4%; }
  .about_name {
    font-size: 3rem;
    letter-spacing: 2px; }
  .bio_sub p {
    font-size: 0.8rem;
    letter-spacing: 1px; } }

@media (max-width: 650px) {
  .about_name {
    font-size: 3rem;
    letter-spacing: 2px; }
  .bio_sub p {
    font-size: 0.6rem; }
  .profile_pic {
    float: none; }
  .about_container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .about_container p {
    text-align: justify;
    font-size: 0.7rem; }
  .about_ed p {
    font-size: 0.7rem;
    text-align: center; } }

@media (max-width: 500px) {
  .bio_name_sub {
    margin-top: 8%; }
  #slideshow {
    width: 300px;
    height: 350px; } }

@media (max-width: 500px) {
  .bio_name_sub {
    width: 80%; } }

@media (max-width: 375px) {
  #slideshow {
    width: 250px; }
  .education1 {
    width: 90%; } }

.calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 30px;
}

.upcoming_events {
  display: flex;
  display: block;
  flex-direction: column;
  align-items: center;
  width: 70%;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
  position: relative;

}

.upcoming_events1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.ue_div {
  width: 100%;
  flex: 100% 1;  
  -webkit-transform: translate3d(0, 0, 0);  
          transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
}

.upcoming_events p {
  margin: 0.5% 0;
}

.ue_sub {
  display: flex;
  position: relative;
}

.ue_1 {
  display: flex;
  align-items: center;
}

.ue_1 p {
  margin-right: 10px;
  width: 70px;
}

.ue_2 {
  border-left: 1px solid rgb(197, 197, 197);
  padding-left: 10px;
}

.ue_2 p:nth-of-type(2n) {
  display: inline-block;
  color: grey;
}

.ue_div h5 {
  border-bottom: 1px solid rgb(197, 197, 197);
  padding: 0 0 1% 1%;
  font-size: 1.1rem;
  font-weight: 800;
  letter-spacing: 2px;
  margin-top: 1%;
  align-self: center;
}

.admin_calendar {
  width: 100%;
}

.admin_delete {
  position: absolute;
  right: 2%;
  top: 30%;
  cursor: pointer;
}

@media (max-width: 900px) {
  .calendar {
    margin-bottom: 0;
    border-bottom: 1px solid rgb(197, 197, 197);
  }

  .home_calendar {
    margin-bottom: 15px;
  }
}

.admin,
.admin1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  padding-top: 60px;
  position: relative; }

.admin1 {
  background: url("https://wallpapertag.com/wallpaper/full/0/b/a/371175-free-download-blurry-background-3840x2160-lockscreen.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.admin_main {
  display: flex;
  flex-direction: column;
  width: 100%; }

.admin_login {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3%;
  height: 60vh;
  box-shadow: 1px 1px 5px grey;
  background: #fff; }

.admin_login > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%; }

.admin_login input {
  margin: 1%;
  background: #fff;
  height: 20px; }

.admin_login input:focus {
  outline: none; }

.admin_login p {
  color: #343434;
  margin-bottom: 0;
  font-size: 0.6em;
  letter-spacing: 1.5px; }

.admin-article-body {
  display: flex;
  flex-direction: column; }

.admin-top-container {
  width: 95%;
  display: flex;
  justify-content: space-between; }

.admin_login h3 {
  font-weight: 200;
  padding: 2%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  background: #232f3e;
  color: #fff; }

.admin_login h3:hover {
  -webkit-transform: scale(1.005);
          transform: scale(1.005); }

.admin_login h3:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98); }

.invalid {
  color: red !important; }

.admin_main_sub {
  display: flex;
  flex-direction: column;
  align-items: center; }

.admin_main_sub > div {
  display: flex;
  justify-content: space-around;
  width: 100%; }

.admin_main_sub2 {
  display: flex;
  flex-direction: column;
  align-items: center; }

.admin h1 {
  font-size: 2.5rem; }

.events_input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-shadow: 1px 1px 5px #afafaf; }

.events_input2 h2 {
  font-size: 1.2em; }

.ce_title {
  margin-top: 5%; }

.events_input > div {
  display: flex;
  flex-direction: column;
  padding: 2%; }

.events_input h3 {
  align-self: center;
  cursor: pointer; }

.events_input h3:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02); }

.events_input h3:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98); }

.events_input input:focus {
  outline: none;
  background: #f2f2f2; }

.admin_link {
  color: #383838;
  text-decoration: none; }

.admin_link:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02); }

.admin_link:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98); }

.calendar_view {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 1%; }

.admin_map {
  width: 100%; }

.requests_map {
  border-bottom: 2px solid #5e5e5e52;
  box-shadow: 1px 1px 10px -2px #5e5e5e52;
  width: 100%;
  padding: 1%;
  margin-bottom: 1%;
  position: relative; }

.form_del {
  position: absolute;
  top: 0;
  right: 3%;
  cursor: pointer; }

.requests_map h5 {
  letter-spacing: 1px;
  border-bottom: 1px solid #bebebe;
  margin: 5% 0;
  width: 100%; }

.admin_sub1 {
  width: 30%;
  margin-bottom: 3%; }

.admin_sub2 {
  width: 50%;
  margin-bottom: 3%; }

.admin_sub3 {
  width: 100%; }

.admin_calendar {
  width: 100%; }

.admin_title {
  text-align: center; }

.admin_links {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 4%;
  cursor: pointer;
  opacity: 0.6; }

.admin_side {
  color: #d4d4d4;
  height: 40vh !important;
  align-items: flex-start !important; }
  .admin_side a {
    text-decoration: none;
    color: inherit;
    margin: 0 !important; }
  .admin_side h4:hover,
  .admin_side a:hover {
    color: #fff;
    cursor: pointer; }
  .admin_side section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 70%;
    margin-left: 3%; }
    .admin_side section img {
      height: 30px;
      color: #fff; }

.admin_links a:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02); }

.admin_main nav {
  width: 100vw;
  text-align: center;
  position: relative; }

#admin1 {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 10%;
  left: 3%;
  z-index: 999; }

#admin1:hover {
  cursor: pointer; }

#admin1 .burger {
  background: #232F3E;
  border-color: #232F3E;
  height: 2px; }

#admin1:hover .burger {
  opacity: .6; }

.article {
  padding-top: 8%;
  display: flex;
  flex-direction: column;
  align-items: center; }

/* article cards */
.article_main {
  display: flex;
  flex-direction: column;
  align-items: center; }

.main_articles {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center; }

.filter_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw; }

.article_list_map {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
  margin: 1%;
  margin-bottom: 20px;
  overflow: hidden;
  border-bottom: 1px solid #bdbdbd;
  padding: 0.5%;
  transition: 1s;
  position: relative; }
  .article_list_map img {
    border-radius: 5px;
    transition: 0.2s;
    height: 300px;
    width: 500px;
    top: 0;
    opacity: 0.9;
    object-fit: cover; }
  .article_list_map h2 {
    font-size: 1.5rem; }
    .article_list_map h2:hover {
      opacity: 0.8; }
  .article_list_map:hover {
    box-shadow: 1px 1px 5px grey;
    transition: 1s;
    background: #fafafa;
    border-radius: 5px; }

.alm_content {
  background: #fff;
  margin: 2%;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  min-width: 95%;
  transition: 1s; }

.alm_btm {
  display: flex;
  justify-content: space-between; }

.alm_btm p:nth-of-type(1) {
  color: #b67a46; }

.article_list_map:hover .alm_content {
  background: #fafafa;
  transition: 1s; }

/* ---- */
.article_top_map {
  display: flex;
  flex-direction: column;
  align-items: center; }

.admin-article-wrapper {
  width: 95%;
  margin: auto;
  margin-bottom: 50px; }
  .admin-article-wrapper .admin-pagination {
    display: flex;
    margin: auto;
    margin-top: 30px; }
    .admin-article-wrapper .admin-pagination .paginated-active {
      border-bottom: 1px solid #232f3e; }
    .admin-article-wrapper .admin-pagination div {
      margin: 5px 20px;
      color: #232f3e;
      cursor: pointer; }
    .admin-article-wrapper .admin-pagination button {
      border: 0;
      color: #232f3e;
      cursor: pointer; }
    .admin-article-wrapper .admin-pagination button:disabled {
      cursor: auto;
      opacity: 0.5; }

.admin-article-map {
  margin-left: 20px;
  flex-direction: row;
  margin: auto;
  width: 80%;
  justify-content: space-between;
  border-bottom: 1px solid #c7c7c7; }
  .admin-article-map button {
    border: 0;
    border-left: 1px solid #c7c7c7;
    cursor: pointer; }
  .admin-article-map button:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    opacity: 0.7; }

.article a,
.article_top_map a {
  display: inline-block;
  color: #525252;
  text-decoration: none; }

.article_top_map h2 {
  color: #525252;
  font-size: 1rem;
  display: list-item;
  list-style-image: url("https://image.flaticon.com/icons/svg/171/171322.svg"); }

.article_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.article_search {
  width: 500px;
  height: 30px;
  border: 1px solid grey;
  border-bottom: 2px solid #000;
  font-size: 1.5rem;
  margin-bottom: 5%; }

.article_search:focus {
  outline: none; }

@media (max-width: 1041px) {
  .article_list_map,
  .article_list_map img {
    width: 425px; }
  .article_list_map img {
    height: 275px; } }

@media (max-width: 886px) {
  .article_list_map,
  .article_list_map img {
    width: 600px; }
  .article_list_map img {
    height: 300px; }
  .alm_content {
    min-height: inherit; } }

@media (max-width: 650px) {
  .article_list_map {
    width: 90%; }
  .article_list_map img {
    height: 250px;
    width: 98%;
    margin: 2px auto; }
  .alm_content p {
    font-size: 0.8rem; }
  .article_search {
    width: 400px; }
  .article_list_map h2 {
    font-size: 0.8rem; } }

@media (max-width: 430px) {
  .article_list_map img {
    height: 200px; }
  .article_search {
    width: 300px; }
  .alm_content p {
    font-size: 0.6rem; }
  .article_list_map h2 {
    font-size: 0.8rem; }
  .article h1 {
    font-size: 1.8rem; } }

.svg {
  height: 40px; }

.blog_main {
  min-height: 90vh;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 5vh;
  align-items: center;
}

.blog_main input:focus,
textarea:focus {
  outline: none;
}

.blog_title {
  width: 50vw;
  height: 2em;
  font-size: 1em;
  margin: auto;
}

.blog_main button {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.blog_main h1 {
  margin: 30px 0;
}

.test_blog {
  white-space: pre-line;
}

.font_style {
  display: flex;
  justify-content: space-between;
  width: 90vw;
  margin: auto;
  margin-bottom: 1vh;
}

.font-size {
  width: 30px;
}

button > img {
  height: 13px;
}

.font_style button {
  padding: 5px 10px;
  margin: 2px;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
}

.font_style button:hover {
  opacity: 0.7;
}

.font_style button:active {
  -webkit-transform: scale(0.99);
          transform: scale(0.99);
}

pre {
  font-family: sans-serif;
  white-space: pre-wrap;
}

#blog,
.blog_text {
  width: 80vw;
  min-height: 30vh;
  margin: auto;
}

.blog_text h2 {
  font-size: 24px;
}

.blog_text {
  box-shadow: 1px 1px 5px 5px #a7a7a7;
}

.blog_preview {
  width: 80vw;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: auto;
  margin-bottom: inherit;
  margin-top: 5vh;
}

.blog_preview span {
  background: #fff;
  padding: 0 10px;
}

.create-blog-image-text {
  margin-top: 0;
  margin-bottom: 30px;
}

.blog_topics {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

#create-blog-send {
  cursor: pointer;
  width: 150px;
  height: 30px;
  border-radius: 4px;
  background: #232f3e;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Image Uploader */

.image_uploader {
  width: 40vw;
  min-height: 40vh;
  margin: 3vh auto;
  border: 1px dotted #9f9f9f;
}

.image_uploader_container {
  overflow: auto;
}

.ce_image_dropzone {
  height: 225px;
  width: 225px;
  border-radius: 1%;
  border: 1px dotted black;
}

.ce_dropzone_text {
  text-align: center;
  padding: 5% 2%;
}

.ep_upload_pic {
  height: 100%;
  width: 100%;
}

/* individual */

.blog_cont {
  min-height: 100vh;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog_cont h1 {
  text-align: center;
  color: #525252;
  margin: 40px 0 30px 0;
}

.blog_cont img {
  height: 60vh;
  width: 80vw;
  /* width: 800px;
  max-width: 95%; */
  /* max-height: 100%; */
  /* height: 400px; */
  margin: 0 auto;
  border-radius: 5px;
  object-fit: cover;
}

.create-blog-button-holder {
  display: flex;
  flex-wrap: wrap;
}

.blog_cont a {
  color: #b67a46;
}

.blog_sub {
  width: 80vw;
  margin: auto;
  text-align: right;
}

.individual {
  margin-top: 8vh;
  box-shadow: 0 4px 2px -2px #bfbfbf;
  padding-bottom: 15vh;
  line-height: 1.7;
}

.share-icons {
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
  width: 80vw;
}

.share-icons p {
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 20px 0;
  opacity: 0.4;
  display: flex;
  align-items: center;
}

.share-icons div {
  margin: 0 3px;
  cursor: pointer;
}
.share-icons div:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01)
}

mark {
  background: none;
  color: #b67a46;
}

.blog_sub mark {
  margin-right: 2px;
}

.blog_mark {
  opacity: 0.4;
}

.blog-keywords {
  margin-top: 30px;
}

.about-the-author {
  width: 80vw;
  margin: auto;
}

.about-the-author-sub {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.about-the-author-sub > p {
  text-transform: uppercase;
  font-size: 12px;
  box-shadow: 0 4px 2px -2px #bfbfbf;
}

.about-the-author-sub > a {
  text-decoration: none;
}

.about-the-author-sub > a:hover {
  text-decoration: underline;
}

.about-the-author p {
  opacity: 0.8;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 14px;
}

.about-the-author span {
  text-transform: none;
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
}

@media (max-width: 900px) {
  .blog_cont img {
    width: 85vw;
    height: 50vh;
  }

  .blog_sub {
    width: 85vw;
  }
}

@media (max-width: 600px) {
  .blog_cont img,
  .blog_sub,
  .about-the-author {
    width: 95vw;
  }
  .blog_cont img {
    height: 40vh;
  }

  .individual {
    width: 95vw;
  }
}

.book_main {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  height: 100%;
}

.book_title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5%;
}

.book_title h1 {
  font-size: 3rem;
  letter-spacing: 2px;
  text-align: center;
}

.book_container,
.book_container2 {
  display: flex;
  width: 70%;
}

.book_container2 {
  display: none;
}

.book_sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid rgb(221, 221, 221);
}

.book_sub p {
  width: 85%;
  text-align: justify;
}

.book2 {
  height: 300px;
  box-shadow: 1px 1px 5px grey;
  border-radius: 1%;
  margin-right: 6%;
}

.book_sub > div p {
  margin-left: 5%;
  width: 100%;
}

.book_sub > div p:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.book_sub > div p:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.book_sub > div a {
  text-decoration: none;
  color: rgb(28, 107, 153);
}

.buy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
}

.buy_2 {
  justify-content: flex-start;
  width: 85%;
  
}

.book_buy {
  display: flex;
  align-items: center;
}

.book_icon {
  height: 35px;
  width: 40px;
}

/* 3d book test */



@media (max-width: 1000px) {
  .book_container {
    width: 90%;
  }
}

@media (max-width: 850px) {
  .book_container {
    display: none;
  }

  .book_container2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
    width: 90%;
  }

  .book_title h1 {
    font-size: 2rem;
  }

  .bc_top p {
    font-size: .8em;
  }

  .book2 {
    float: left;
    margin-bottom: 2%;
  }

  .book_sub {
    border-left: 0;    
    border-top: 1px solid rgb(221, 221, 221);
  }

  .book_sub p {
    text-align: center;
  }

  .buy_2 {
    align-items: center;
  }
}

@media (max-width: 415px) {
  .bc_top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .book_title h1 {
    font-size: 1.2rem;
  }

  .book2 {
    width: 200px

  }
}

.contact {
  display: flex;
  justify-content: space-between;
  padding-top: 6%;
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

.speaker_name {
  text-align: center;
  font-size: 2rem;
  letter-spacing: 2px;
  color: rgb(60, 60, 60);
}

.contact_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
}

.contact_sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 0;
  width: 100%;
}

.request_form {
  width: 50%;
  padding: 2%;
  margin: 5% 0;
  margin-left: 10%;
}

.request_form h3 {
  text-align: center;
  color: rgb(58, 58, 58);
  font-weight: 300;
}

.btn_box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #232f3e;
  width: 200px;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
}

.contact_form {
  text-decoration: none;
  color: rgb(238, 238, 238);
}

.contact_form:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.contact_form:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.contact_sub > div {
  display: flex;
  justify-content: space-around;
}

.ci_holder {
  width: 400px;
  height: 500px;
  right: 0;
  opacity: 0.9;
}

.contact_img {
  height: 100%;
  width: 100%;
}

.form_pic_btm {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 980px) {
  .contact_1 {
    width: 100%;
  }
}

@media (max-width: 875px) {
  .form_pic_btm {
    flex-direction: column;
    align-items: center;
  }

  .contact_sub {
    margin-left: 0;
    width: 100%;
  }

  .ci_holder {
    width: auto;
    height: auto;
    margin: 0 10%;
  }
}

@media (max-width: 700px) {
  .speaker_name {
    font-size: 1.5rem;
  }

  .request_form h3 {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .speaker_name {
    font-size: 1rem;
    margin-top: 10%;
  }

  .form_pic_btm h3 {
    font-size: 0.8rem;
    margin: 5% 0 10% 0;
  }

  .ci_holder {
    margin: 0;
  }
}

.home_img {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-image: url(https://res.cloudinary.com/dwvrok1le/image/upload/v1547432341/deron.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
  z-index: -1;
  -webkit-animation: fadeInOut 1s;
          animation: fadeInOut 1s;
}

.home_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.home_sub {
  background-image: url(/static/media/dust_scratches.608ab5fe.png);
  margin-bottom: -60px;
  display: flex;
  justify-content: space-around;
  padding-top: 2%;
}

.home_sub h3 {
  color: rgb(146, 125, 125);
}

.article_link {
  text-decoration: none;
}

.home_article p {
  color: rgb(82, 82, 82);
}

.home_article p:hover,
.cont_email p:hover {
  opacity: 0.8;
}

.home_article p:active,
.cont_email p:active {
  -webkit-transform: scale(0.995);
          transform: scale(0.995);
}

.home_container h1 {
  font-family: 'Lato', sans-serif;
  font-size: 1.4rem;
  font-weight: 200;
  letter-spacing: 2px;
  color: #fff;
  -webkit-animation: fadeInOutText 2s;
          animation: fadeInOutText 2s;
}

.contact_holder {
  height: 175px;
}

.contact_holder h3 {
  text-align: center;
}

.contact_email {
  text-decoration: none;
  letter-spacing: 2px;
  color: #000;
  cursor: pointer;
}

.cont_email p:hover {
  opacity: 0.8;
}

.ch1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_holder p {
  text-align: center;
  font-size: 0.9rem;
  letter-spacing: 2px;
  color: rgb(82, 82, 82);
}

.social_media {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5%;
}

.social_media > div {
  display: flex;
  justify-content: space-around;
  margin: 2% 0;
}

.social_icon {
  /* height: 35px;
  width: 40px; */
}

.social_icon:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.twitter {
  border-radius: 50%;
}

.home_sub_2 {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.home_calendar {
  width: 50%;
  margin-bottom: 70px;
}

.home_sub12 {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 80px;
  align-items: center;
}

.home_image {
  margin-top: 5%;
  align-self: center;
  width: 80%;
}

.home_article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  border-bottom: 1px solid rgb(197, 197, 197);
  padding-bottom: 2%;
  margin: 0 10%;
}

/* Arrow */

.scroll_to_top {
  position: fixed;
  left: 50%;
  -webkit-transform: translate(-50%) rotate(180deg);
          transform: translate(-50%) rotate(180deg);
  bottom: 15px;
  width: 25px;
  height: 25px;
  background-image: url('https://image.flaticon.com/icons/svg/118/118738.svg');
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  cursor: pointer;
  -webkit-animation: bounce 2.5s infinite;
          animation: bounce 2.5s infinite;
}

.scroll_to_top:hover {
  opacity: 0.3;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}

@-webkit-keyframes fadeInOut {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.9;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.9;
  }
}

@-webkit-keyframes fadeInOutText {
  0% {
    opacity: 0;
    -webkit-transform: translate(-10px);
            transform: translate(-10px);
  }

  40% {
    opacity: 0;
    -webkit-transform: translate(-10px);
            transform: translate(-10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes fadeInOutText {
  0% {
    opacity: 0;
    -webkit-transform: translate(-10px);
            transform: translate(-10px);
  }

  40% {
    opacity: 0;
    -webkit-transform: translate(-10px);
            transform: translate(-10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@media (max-width: 1100px) {
  .home_sub_2 {
    flex-direction: column;
  }

  .upcoming_events {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .home_sub {
    flex-direction: column-reverse;
    align-items: center;
  }

  .upcoming_events {
    width: 90%;
  }

  .home_sub12,
  .home_article {
    width: 95%;
  }

  .home_sub12 {
    margin-top: -8%;
  }
  .home_sub_2 {
    flex-direction: row;
    width: 90%;
    margin-bottom: 70px;
  }

  .contact_holder {
    width: 50%;
  }

  .home_calendar {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .article_wrapper {
    margin: 0 10%;
  }
}

@media (max-width: 500px) {
  .home_sub_2 {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
  }

  .contact_holder {
    width: 95%;
  }

  .ue_2 p {
    font-size: 0.7rem;
  }
}

@media (max-width: 450px) {
  .article_top_map h2 {
    font-size: 0.8rem;
    width: 100%;
  }
}

.form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8% 0; }

/* ---- tracker ---- */
.form_tracker {
  display: flex;
  width: 50%;
  justify-content: space-around;
  align-items: center; }
  .form_tracker div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #4c6d7c; }
  .form_tracker p {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: grey;
    opacity: 0.2; }

/* ----------- */
.form_main {
  width: 50%; }
  .form_main h2 {
    text-align: center; }

.has-float-label {
  font-size: 75%;
  margin: 4% 2% 1% 2%; }

.input_field {
  width: 100%; }

.floating_placeholder {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 18px;
  transition: 0.2s ease all;
  opacity: 0.7; }

.input_field:focus ~ .floating_placeholder,
.input_field:not(:focus):valid .floating_placeholder {
  top: 8px;
  bottom: 10px;
  left: 20px;
  font-size: 1px;
  opacity: 1; }

/* -------- */
.form_btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5%;
  height: 100%; }
  .form_btn img {
    width: 30px;
    height: 30px;
    cursor: pointer; }
    .form_btn img:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .form_btn img:active {
      -webkit-transform: scale(0.98);
              transform: scale(0.98); }

.submit_btn {
  cursor: pointer; }

.submit_btn:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05); }

.submit_btn:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98); }

.validation {
  color: red;
  text-align: center; }

@media (max-width: 1100px) {
  .form_main {
    width: 90vw; } }

@media (max-width: 800px) {
  .form_tracker {
    margin-top: 5vh; }
  .form_container h1 {
    font-size: 1.5em; }
  .form_main h2 {
    font-size: 1em; }
  .has-float-label input:placeholder-shown:not(:focus) + *,
  .has-float-label select:placeholder-shown:not(:focus) + *,
  .has-float-label textarea:placeholder-shown:not(:focus) + * #ce_title {
    font-size: 120%; } }

.portfolio {
  padding-top: 8%;
  font-family: 'Roboto', sans-serif;
}

.portfolio_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio_container h1 {
  font-size: 3.5rem;
  letter-spacing: 2px;
  color: rgb(60, 60, 60);
}

.portfolio_sub,
.portfolio_sub1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin: 2% 0 5% 0;
}

.portfolio_sub {
  width: 90vw;
}

.books_top {
  padding-bottom: 4%;
  border-bottom: 1px solid rgb(240, 240, 240);
  flex-wrap: wrap;
  justify-content: space-around;
}

.portfolio_sub > div {
  margin: 1%;
}

.portfolio_sub p {
  padding: 1%;
}

.portfolio_sub h2 {
  padding: 0 2%;
  -webkit-animation: titleFade 1.5s;
          animation: titleFade 1.5s;
}

.portfolio_sub div:nth-of-type(2) {
  text-align: end;
}

.book {
  height: 300px;
  width: 200px;
  box-shadow: 1px 1px 5px grey;
  border-radius: 1%;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-animation: flip-vertical-right 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
          animation: flip-vertical-right 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}

.book:hover {
  opacity: 0.9;
  box-shadow: 1px 1px 10px #000;
}

.library_carousel {
  margin: 3% 0;
}

.library_carousel h2 {
  font-size: 2rem;
  letter-spacing: 2px;
}

.library_carousel h4 {
  text-align: center;
}

.lib_btns {
  display: flex;
  justify-content: space-around;
}

.lib_btn {
  border: none;
}

.lib_btn:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.lib_btn:active {
  -webkit-transform: scale(0.92);
          transform: scale(0.92);
}

.lib_btn:focus {
  outline: none;
}

.library_carousel img {
  display: block;
  margin: auto;
  width: 98%;
}

.resources_bottom {
  width: 100%;
  border-top: 1px solid rgb(240, 240, 240);
  border-bottom: 1px solid rgb(240, 240, 240);
}

.resources_bottom > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chapel_sermons {
  display: flex;
  justify-content: space-around;
  width: 95%;
}

.chapel_sermons a {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 800;
  color: rgb(146, 125, 125);
  cursor: pointer;
}

.chapel_sermons a:hover {
  opacity: 0.8;
}

.chapel_sermons_sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cs1 {
  border-right: 1px solid #f0f0f0;
  padding-right: 2%;
}

.chapel_sub p {
  padding-left: 2%;
  align-self: start;
  text-align: justify;
}

.chapel_sub {
  display: flex;
  margin: 5% 0;
  width: 100%;
}

.chapel_img {
  width: 175px;
  height: 100px;
  padding-right: 5%;
  transition: 0.5s;
  object-fit: cover;
}

.chapel_sub1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5% 0;
}

.chapel_sub1 a {
  text-align: center;
  margin: 2% 0;
  font-size: 1rem;
}

.chapel_sub1 > p {
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.sermons_map {
  width: 100%;
}

.cs-1 {
  display: flex;
  align-items: center;
  width: 40%;
}

.cs2 {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.preaching_source {
  align-self: center;
  width: 250px !important;
  object-fit: cover;
}

.ps_text {
  width: 80%;
}

.small_map {
  display: none;
}

.chapel_sermons_sub h2:nth-of-type(2) {
  margin-bottom: 0;
}

@-webkit-keyframes titleFade {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px);
  }

  30% {
    opacity: 0;
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes titleFade {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px);
  }

  30% {
    opacity: 0;
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
}

@keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
}

@media (max-width: 1000px) {
  .portfolio_sub > div {
    flex-direction: column;
  }
  .portfolio_sub > div h2 {
    text-align: center;
  }

  .portfolio_sub > div:nth-of-type(2) {
    flex-direction: column-reverse;
    text-align: start;
  }
}

@media (max-width: 975px) {
  .chapel_sermons {
    flex-direction: column;
    width: 95%;
  }

  .cs2 {
    width: 60%;
    height: 100%;
  }

  .cs1 {
    border-right: none;
    border-bottom: 1px solid rgb(240, 240, 240);
    padding-bottom: 3%;
    padding-right: 0;
  }
}

@media (max-width: 800px) {
  .portfolio_sub > div {
    text-align: justify;
  }
}

@media (max-width: 700px) {
  .portfolio_sub > div p {
    font-size: 0.8rem;
  }
}

@media (max-width: 600px) {
  .portfolio_sub {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .cs2 p {
    font-size: 0.8rem;
  }
}

@media (max-width: 500px) {
  .big_map {
    display: none;
  }

  .small_map {
    display: flex;
    flex-direction: column;
  }

  .chapel_sub {
    flex-direction: column;
    align-items: center;
  }
  .cs-1 {
    justify-content: center;
    width: 100%;
  }

  .cs2 {
    width: 90%;
  }

  .cs2 p:nth-of-type(1) {
    position: absolute;
    top: 0;
    right: 2%;
    color: rgb(235, 235, 235);
  }

  .chapel_img {
    width: 100%;
    height: 300px;
    float: top;
    padding-right: 0;
    border: none;
    transition: none;
  }

  .sermons_map {
    margin: 4% 0;
    box-shadow: 1px 1px 5px rgb(163, 163, 163);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* justify-content: space-between; */
  }

  .library_carousel h2 {
    font-size: 1.5rem;
  }

  .sermon_title {
    display: inline-block;
    position: relative;
    text-align: center;
  }

  .sermon_title:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background: rgb(146, 125, 125);

    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: all 0.5s ease-in-out 0s;
  }

  .sermon_title:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@media (max-width: 400px) {
  .library_carousel h2 {
    font-size: 1.3rem;
  }

  .portfolio h1 {
    margin-top: 10%;
    font-size: 2rem;
  }
}

.requests {
  padding-top: 6%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.requests_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.requests_btn_holder {
  display: flex;
  justify-content: center;
}

.print_btn {
  display: inline-block;
  cursor: pointer;
}

.print_btn:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  opacity: .8;
}

.print_btn:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.request_container {
  display: flex;
  flex-direction: column;
  padding: 1%;
  margin: 1%;
  width: 90%;
}

.request_title {
  display: flex;
  justify-content: space-between;
}

.request_sub {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 2px rgb(128, 128, 128);
  padding: 0 5% 1% 5%;
  margin: 0 5%;
}

.request_sub h3 {
  border-bottom: 0.5px solid rgb(161, 161, 161);
  padding-bottom: .5%;
}

.request_sub p {
  margin: 0.5% 0;
}

.email_holder {
  display: flex;
  align-items: center;
}

.email_holder > div {
  margin: 0.5% 2%;
}

.request_email {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.email_icon {
  height: 25px;
}

.email_icon:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.bt_admin {
  display: inline-block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 1%;
  cursor: pointer;
  color: #5a669c;
}

.btn:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.btn:active {
  -webkit-transform: scale(.98);
          transform: scale(.98);  
}
.resources {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resources_top {
    margin-top: 5%;
}

.resources_container {
    width: 60%;
}
.header_container {
  font-family: 'Rambla', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1%;
  height: 60px;
  z-index: 1;
  background: #1b1a1b;
  transition: .4s; }

.header_right {
  width: 40%;
  display: flex;
  justify-content: space-around; }

.header_link {
  text-decoration: none;
  color: #d4d4d4; }

.hl1:focus {
  border-bottom: 2px solid #2a9e7b; }

.header_link:hover {
  color: #fff; }

.hamburger {
  display: none;
  cursor: pointer;
  margin-left: 2%; }

.hamburger_icon {
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.burger {
  height: .5px;
  width: 30px;
  border: 1px solid #d4d4d4;
  border-radius: 20px;
  background: #d4d4d4;
  margin: 10% 0;
  position: relative;
  z-index: -1; }

.hamburger_icon:hover .burger {
  background: #fff;
  border-color: #fff; }

.dropdown {
  position: fixed;
  left: 0;
  top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-animation: menuIn 0.5s;
          animation: menuIn 0.5s;
  height: calc(100vh - 60px);
  width: 200px;
  z-index: 99;
  opacity: .98; }
  .dropdown > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 65vh; }
  .dropdown a {
    color: #d4d4d4;
    margin: 4vh 3vw; }
    .dropdown a:hover {
      color: #fff; }
  .dropdown aside {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    .dropdown aside img {
      height: 40px;
      width: 40px;
      align-self: center;
      margin-bottom: 2vh; }
  .dropdown p {
    color: #d4d4d4;
    text-align: center;
    margin-top: 0; }

.exit {
  -webkit-animation: exit 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
          animation: exit 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both; }

#burger {
  display: none; }

@-webkit-keyframes exit {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0; } }

@keyframes exit {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0; } }

@-webkit-keyframes menuIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px); }
  100% {
    opacity: 0.95;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes menuIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px); }
  100% {
    opacity: 0.95;
    -webkit-transform: translate(0);
            transform: translate(0); } }

.name_main {
  display: flex;
  justify-content: space-between;
  width: 225px; }

.name_link {
  display: none;
  -webkit-animation: leftEntrance 1s;
          animation: leftEntrance 1s; }

.header_main {
  transition: 0.5s; }

.name_main:hover .name_link {
  display: block; }

@keyframes menuIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px); }
  100% {
    opacity: 0.95;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@media (max-width: 1100px) {
  .hl1 {
    font-size: 0.8rem; }
  .header_right {
    justify-content: space-between;
    margin-right: 5%; } }

@media (max-width: 950px) {
  .header_right {
    display: none; }
  .hamburger {
    display: block; }
  .header_link:focus {
    border: none; }
  .header_container {
    justify-content: flex-start; } }

@-webkit-keyframes leftEntrance {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX();
            transform: translateX(); } }

@keyframes leftEntrance {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX();
            transform: translateX(); } }

.footer {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 60px;
  background: #232f3e;
  box-shadow: 1px 1px 5px #1b1a1b;
}

.footer p {
  font-size: 0.8rem;
  color: rgb(212, 212, 212);
}

.footer_container {
  display: flex;
  align-items: center;
}

.olive {
  height: 20px;
}

