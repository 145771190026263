.requests {
  padding-top: 6%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.requests_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.requests_btn_holder {
  display: flex;
  justify-content: center;
}

.print_btn {
  display: inline-block;
  cursor: pointer;
}

.print_btn:hover {
  transform: scale(1.05);
  opacity: .8;
}

.print_btn:active {
  transform: scale(0.98);
}

.request_container {
  display: flex;
  flex-direction: column;
  padding: 1%;
  margin: 1%;
  width: 90%;
}

.request_title {
  display: flex;
  justify-content: space-between;
}

.request_sub {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 2px rgb(128, 128, 128);
  padding: 0 5% 1% 5%;
  margin: 0 5%;
}

.request_sub h3 {
  border-bottom: 0.5px solid rgb(161, 161, 161);
  padding-bottom: .5%;
}

.request_sub p {
  margin: 0.5% 0;
}

.email_holder {
  display: flex;
  align-items: center;
}

.email_holder > div {
  margin: 0.5% 2%;
}

.request_email {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.email_icon {
  height: 25px;
}

.email_icon:hover {
  transform: scale(1.05);
}

.bt_admin {
  display: inline-block;
  width: max-content;
  margin: 1%;
  cursor: pointer;
  color: #5a669c;
}

.btn:hover {
  transform: scale(1.02);
}

.btn:active {
  transform: scale(.98);  
}