.book_main {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  height: 100%;
}

.book_title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5%;
}

.book_title h1 {
  font-size: 3rem;
  letter-spacing: 2px;
  text-align: center;
}

.book_container,
.book_container2 {
  display: flex;
  width: 70%;
}

.book_container2 {
  display: none;
}

.book_sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid rgb(221, 221, 221);
}

.book_sub p {
  width: 85%;
  text-align: justify;
}

.book2 {
  height: 300px;
  box-shadow: 1px 1px 5px grey;
  border-radius: 1%;
  margin-right: 6%;
}

.book_sub > div p {
  margin-left: 5%;
  width: 100%;
}

.book_sub > div p:hover {
  transform: scale(1.02);
}

.book_sub > div p:active {
  transform: scale(0.98);
}

.book_sub > div a {
  text-decoration: none;
  color: rgb(28, 107, 153);
}

.buy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
}

.buy_2 {
  justify-content: flex-start;
  width: 85%;
  
}

.book_buy {
  display: flex;
  align-items: center;
}

.book_icon {
  height: 35px;
  width: 40px;
}

/* 3d book test */



@media (max-width: 1000px) {
  .book_container {
    width: 90%;
  }
}

@media (max-width: 850px) {
  .book_container {
    display: none;
  }

  .book_container2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
    width: 90%;
  }

  .book_title h1 {
    font-size: 2rem;
  }

  .bc_top p {
    font-size: .8em;
  }

  .book2 {
    float: left;
    margin-bottom: 2%;
  }

  .book_sub {
    border-left: 0;    
    border-top: 1px solid rgb(221, 221, 221);
  }

  .book_sub p {
    text-align: center;
  }

  .buy_2 {
    align-items: center;
  }
}

@media (max-width: 415px) {
  .bc_top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .book_title h1 {
    font-size: 1.2rem;
  }

  .book2 {
    width: 200px

  }
}
