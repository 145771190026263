.portfolio {
  padding-top: 8%;
  font-family: 'Roboto', sans-serif;
}

.portfolio_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio_container h1 {
  font-size: 3.5rem;
  letter-spacing: 2px;
  color: rgb(60, 60, 60);
}

.portfolio_sub,
.portfolio_sub1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin: 2% 0 5% 0;
}

.portfolio_sub {
  width: 90vw;
}

.books_top {
  padding-bottom: 4%;
  border-bottom: 1px solid rgb(240, 240, 240);
  flex-wrap: wrap;
  justify-content: space-around;
}

.portfolio_sub > div {
  margin: 1%;
}

.portfolio_sub p {
  padding: 1%;
}

.portfolio_sub h2 {
  padding: 0 2%;
  animation: titleFade 1.5s;
}

.portfolio_sub div:nth-of-type(2) {
  text-align: end;
}

.book {
  height: 300px;
  width: 200px;
  box-shadow: 1px 1px 5px grey;
  border-radius: 1%;
  transform: rotateY(180deg);
  animation: flip-vertical-right 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}

.book:hover {
  opacity: 0.9;
  box-shadow: 1px 1px 10px #000;
}

.library_carousel {
  margin: 3% 0;
}

.library_carousel h2 {
  font-size: 2rem;
  letter-spacing: 2px;
}

.library_carousel h4 {
  text-align: center;
}

.lib_btns {
  display: flex;
  justify-content: space-around;
}

.lib_btn {
  border: none;
}

.lib_btn:hover {
  transform: scale(0.95);
}

.lib_btn:active {
  transform: scale(0.92);
}

.lib_btn:focus {
  outline: none;
}

.library_carousel img {
  display: block;
  margin: auto;
  width: 98%;
}

.resources_bottom {
  width: 100%;
  border-top: 1px solid rgb(240, 240, 240);
  border-bottom: 1px solid rgb(240, 240, 240);
}

.resources_bottom > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chapel_sermons {
  display: flex;
  justify-content: space-around;
  width: 95%;
}

.chapel_sermons a {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 800;
  color: rgb(146, 125, 125);
  cursor: pointer;
}

.chapel_sermons a:hover {
  opacity: 0.8;
}

.chapel_sermons_sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cs1 {
  border-right: 1px solid #f0f0f0;
  padding-right: 2%;
}

.chapel_sub p {
  padding-left: 2%;
  align-self: start;
  text-align: justify;
}

.chapel_sub {
  display: flex;
  margin: 5% 0;
  width: 100%;
}

.chapel_img {
  width: 175px;
  height: 100px;
  padding-right: 5%;
  transition: 0.5s;
  object-fit: cover;
}

.chapel_sub1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5% 0;
}

.chapel_sub1 a {
  text-align: center;
  margin: 2% 0;
  font-size: 1rem;
}

.chapel_sub1 > p {
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.sermons_map {
  width: 100%;
}

.cs-1 {
  display: flex;
  align-items: center;
  width: 40%;
}

.cs2 {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.preaching_source {
  align-self: center;
  width: 250px !important;
  object-fit: cover;
}

.ps_text {
  width: 80%;
}

.small_map {
  display: none;
}

.chapel_sermons_sub h2:nth-of-type(2) {
  margin-bottom: 0;
}

@keyframes titleFade {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }

  30% {
    opacity: 0;
    transform: translateX(-40px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes flip-vertical-right {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0);
  }
}

@media (max-width: 1000px) {
  .portfolio_sub > div {
    flex-direction: column;
  }
  .portfolio_sub > div h2 {
    text-align: center;
  }

  .portfolio_sub > div:nth-of-type(2) {
    flex-direction: column-reverse;
    text-align: start;
  }
}

@media (max-width: 975px) {
  .chapel_sermons {
    flex-direction: column;
    width: 95%;
  }

  .cs2 {
    width: 60%;
    height: 100%;
  }

  .cs1 {
    border-right: none;
    border-bottom: 1px solid rgb(240, 240, 240);
    padding-bottom: 3%;
    padding-right: 0;
  }
}

@media (max-width: 800px) {
  .portfolio_sub > div {
    text-align: justify;
  }
}

@media (max-width: 700px) {
  .portfolio_sub > div p {
    font-size: 0.8rem;
  }
}

@media (max-width: 600px) {
  .portfolio_sub {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .cs2 p {
    font-size: 0.8rem;
  }
}

@media (max-width: 500px) {
  .big_map {
    display: none;
  }

  .small_map {
    display: flex;
    flex-direction: column;
  }

  .chapel_sub {
    flex-direction: column;
    align-items: center;
  }
  .cs-1 {
    justify-content: center;
    width: 100%;
  }

  .cs2 {
    width: 90%;
  }

  .cs2 p:nth-of-type(1) {
    position: absolute;
    top: 0;
    right: 2%;
    color: rgb(235, 235, 235);
  }

  .chapel_img {
    width: 100%;
    height: 300px;
    float: top;
    padding-right: 0;
    border: none;
    transition: none;
  }

  .sermons_map {
    margin: 4% 0;
    box-shadow: 1px 1px 5px rgb(163, 163, 163);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* justify-content: space-between; */
  }

  .library_carousel h2 {
    font-size: 1.5rem;
  }

  .sermon_title {
    display: inline-block;
    position: relative;
    text-align: center;
  }

  .sermon_title:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background: rgb(146, 125, 125);

    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
  }

  .sermon_title:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@media (max-width: 400px) {
  .library_carousel h2 {
    font-size: 1.3rem;
  }

  .portfolio h1 {
    margin-top: 10%;
    font-size: 2rem;
  }
}
