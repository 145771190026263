.header_container {
  font-family: 'Rambla', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1%;
  height: 60px;
  z-index: 1;
  background: #1b1a1b;
  transition: .4s;
}

.header_right {
  width: 40%;
  display: flex;
  justify-content: space-around;
}

.header_link {
  text-decoration: none;
  color: #d4d4d4;
}

.hl1:focus {
  border-bottom: 2px solid #2a9e7b;
}

.header_link:hover {
  color: #fff;
}

.hamburger {
  display: none;
  cursor: pointer;
  margin-left: 2%;
}

.hamburger_icon {
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.burger {
  height: .5px;
  width: 30px;
  border: 1px solid #d4d4d4;
  border-radius: 20px;
  background: #d4d4d4;
  margin: 10% 0;
  position: relative;
  z-index: -1;
}

.hamburger_icon:hover .burger{
  background: #fff;
  border-color: #fff;
}

.dropdown {
  position: fixed;
  left: 0;
  top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: menuIn 0.5s;
  height: calc(100vh - 60px);
  width: 200px;
  z-index: 99;
  opacity: .98;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 65vh;  
  }

  a {
    color: #d4d4d4;
    margin: 4vh 3vw;

    &:hover {
      color: #fff;
    }
  }

  aside {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

     img {
      height: 40px;
      width: 40px;
      align-self: center;
      margin-bottom: 2vh;
    }
  }
  p {
    color: #d4d4d4;
    text-align: center;
    margin-top: 0;
  }
}

.exit {
  animation: exit 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

#burger {
  display: none;
}

@keyframes exit {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-1000px);
    opacity: 0;
  }
}

@keyframes menuIn {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }

  100% {
    opacity: 0.95;
    transform: translate(0);
  }
}

.name_main {
  display: flex;
  justify-content: space-between;
  width: 225px;
}

.name_link {
  display: none;
  animation: leftEntrance 1s;
}

.header_main {
  transition: 0.5s;
}

.name_main:hover .name_link {
  display: block;
}

@keyframes menuIn {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }

  100% {
    opacity: 0.95;
    transform: translate(0);
  }
}

@media (max-width: 1100px) {
  .hl1 {
    font-size: 0.8rem;
  }

  .header_right {
    justify-content: space-between;
    margin-right: 5%;
  }
}

@media (max-width: 950px) {
  .header_right {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .header_link:focus {
    border: none;
  }

  .header_container {
    justify-content: flex-start;
  }
}

@keyframes leftEntrance {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX();
  }
}
