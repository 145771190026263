.bio_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8%;
  width: 100%;
  opacity: 1;
  background: #fdfdfd;
}

.bio_name_sub {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bio_sub p {
  text-align: center;
  font-size: 0.9rem;
  letter-spacing: 2px;
}

.about_name {
  text-align: center;
  height: 100%;
  font-size: 3.5rem;
  letter-spacing: 2px;
  color: rgb(60, 60, 60);
}

.about_container {
  background: #fdfdfd;
  padding: 5% 10% 0 10%;
  margin-bottom: 2%;
  opacity: 1;

  p {
    font-size: 1rem;
  }
}

.profile_pic {
  float: left;
  margin: 1% 2%;
  height: 400px;
}

.about_ed {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 5px rgb(216, 216, 216);
  padding: 1%;
}

.about_ed > div:nth-of-type(2) {
  height: 300px;
  display: flex;
  flex-direction: row;
}

.about_ed_sub1 {
  display: flex;
  letter-spacing: 2px;
}

.btm_line_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  width: 100%;
}

.btm_a_line {
  width: 20px;
  border: 1px solid rgb(173, 173, 173);
  background: rgb(173, 173, 173);
  margin: 5%;
}

.education1 {
  position: relative;
}

.education_img {
  width: 100px;
  position: absolute;
  z-index: -1;
  opacity: 0.3;
  top: 2%;
}

.img1 {
  left: 2%;
}

.img2 {
  right: 2%;
}

.education_title {
  margin-top: 3.5%;
}

.about_ed p {
  font-size: 0.9rem;
}

.line_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2%;
}

.a_line {
  width: 40px;
  border: 1px solid rgb(173, 173, 173);
  background: rgb(173, 173, 173);
  margin: 5%;
}

.about_bottom {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

#slideshow {
  position: relative;
  width: 400px;
  height: 500px;
  padding: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

  > div {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
  }
}

.image_scroll {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.image_scroll2 {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

@keyframes fadeInOutAbout {
  0% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.3;
  }
}

@media (max-width: 1100px) {
  .about_bottom {
    flex-direction: column;
  }

  .about_ed {
    margin: 2% 0;
  }
}

@media (max-width: 800px) {
  .bio_name_sub {
    margin-top: 4%;
  }

  .about_name {
    font-size: 3rem;
    letter-spacing: 2px;
  }

  .bio_sub p {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
}

@media (max-width: 650px) {
  .about_name {
    font-size: 3rem;
    letter-spacing: 2px;
  }

  .bio_sub p {
    font-size: 0.6rem;
  }

  .profile_pic {
    float: none;
  }

  .about_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about_container p {
    text-align: justify;
    font-size: 0.7rem;
  }

  .about_ed p {
    font-size: 0.7rem;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .bio_name_sub {
    margin-top: 8%;
  }

  #slideshow {
    width: 300px;
    height: 350px;
  }
}

@media (max-width: 500px) {
  .bio_name_sub {
    width: 80%;
  }
}

@media (max-width: 375px) {
  #slideshow {
    width: 250px;
  }

  .education1 {
    width: 90%;
  }
}
