.blog_main {
  min-height: 90vh;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 5vh;
  align-items: center;
}

.blog_main input:focus,
textarea:focus {
  outline: none;
}

.blog_title {
  width: 50vw;
  height: 2em;
  font-size: 1em;
  margin: auto;
}

.blog_main button {
  width: max-content;
}

.blog_main h1 {
  margin: 30px 0;
}

.test_blog {
  white-space: pre-line;
}

.font_style {
  display: flex;
  justify-content: space-between;
  width: 90vw;
  margin: auto;
  margin-bottom: 1vh;
}

.font-size {
  width: 30px;
}

button > img {
  height: 13px;
}

.font_style button {
  padding: 5px 10px;
  margin: 2px;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
}

.font_style button:hover {
  opacity: 0.7;
}

.font_style button:active {
  transform: scale(0.99);
}

pre {
  font-family: sans-serif;
  white-space: pre-wrap;
}

#blog,
.blog_text {
  width: 80vw;
  min-height: 30vh;
  margin: auto;
}

.blog_text h2 {
  font-size: 24px;
}

.blog_text {
  box-shadow: 1px 1px 5px 5px #a7a7a7;
}

.blog_preview {
  width: 80vw;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: auto;
  margin-bottom: inherit;
  margin-top: 5vh;
}

.blog_preview span {
  background: #fff;
  padding: 0 10px;
}

.create-blog-image-text {
  margin-top: 0;
  margin-bottom: 30px;
}

.blog_topics {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

#create-blog-send {
  cursor: pointer;
  width: 150px;
  height: 30px;
  border-radius: 4px;
  background: #232f3e;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Image Uploader */

.image_uploader {
  width: 40vw;
  min-height: 40vh;
  margin: 3vh auto;
  border: 1px dotted #9f9f9f;
}

.image_uploader_container {
  overflow: auto;
}

.ce_image_dropzone {
  height: 225px;
  width: 225px;
  border-radius: 1%;
  border: 1px dotted black;
}

.ce_dropzone_text {
  text-align: center;
  padding: 5% 2%;
}

.ep_upload_pic {
  height: 100%;
  width: 100%;
}

/* individual */

.blog_cont {
  min-height: 100vh;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog_cont h1 {
  text-align: center;
  color: #525252;
  margin: 40px 0 30px 0;
}

.blog_cont img {
  height: 60vh;
  width: 80vw;
  /* width: 800px;
  max-width: 95%; */
  /* max-height: 100%; */
  /* height: 400px; */
  margin: 0 auto;
  border-radius: 5px;
  object-fit: cover;
}

.create-blog-button-holder {
  display: flex;
  flex-wrap: wrap;
}

.blog_cont a {
  color: #b67a46;
}

.blog_sub {
  width: 80vw;
  margin: auto;
  text-align: right;
}

.individual {
  margin-top: 8vh;
  box-shadow: 0 4px 2px -2px #bfbfbf;
  padding-bottom: 15vh;
  line-height: 1.7;
}

.share-icons {
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
  width: 80vw;
}

.share-icons p {
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 20px 0;
  opacity: 0.4;
  display: flex;
  align-items: center;
}

.share-icons div {
  margin: 0 3px;
  cursor: pointer;
}
.share-icons div:hover {
  transform: scale(1.01)
}

mark {
  background: none;
  color: #b67a46;
}

.blog_sub mark {
  margin-right: 2px;
}

.blog_mark {
  opacity: 0.4;
}

.blog-keywords {
  margin-top: 30px;
}

.about-the-author {
  width: 80vw;
  margin: auto;
}

.about-the-author-sub {
  width: max-content;
}

.about-the-author-sub > p {
  text-transform: uppercase;
  font-size: 12px;
  box-shadow: 0 4px 2px -2px #bfbfbf;
}

.about-the-author-sub > a {
  text-decoration: none;
}

.about-the-author-sub > a:hover {
  text-decoration: underline;
}

.about-the-author p {
  opacity: 0.8;
  width: max-content;
  font-size: 14px;
}

.about-the-author span {
  text-transform: none;
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
}

@media (max-width: 900px) {
  .blog_cont img {
    width: 85vw;
    height: 50vh;
  }

  .blog_sub {
    width: 85vw;
  }
}

@media (max-width: 600px) {
  .blog_cont img,
  .blog_sub,
  .about-the-author {
    width: 95vw;
  }
  .blog_cont img {
    height: 40vh;
  }

  .individual {
    width: 95vw;
  }
}
