.calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 30px;
}

.upcoming_events {
  display: flex;
  display: block;
  flex-direction: column;
  align-items: center;
  width: 70%;
  transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
  position: relative;

}

.upcoming_events1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.ue_div {
  width: 100%;
  flex: 100%;  
  transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
}

.upcoming_events p {
  margin: 0.5% 0;
}

.ue_sub {
  display: flex;
  position: relative;
}

.ue_1 {
  display: flex;
  align-items: center;
}

.ue_1 p {
  margin-right: 10px;
  width: 70px;
}

.ue_2 {
  border-left: 1px solid rgb(197, 197, 197);
  padding-left: 10px;
}

.ue_2 p:nth-of-type(2n) {
  display: inline-block;
  color: grey;
}

.ue_div h5 {
  border-bottom: 1px solid rgb(197, 197, 197);
  padding: 0 0 1% 1%;
  font-size: 1.1rem;
  font-weight: 800;
  letter-spacing: 2px;
  margin-top: 1%;
  align-self: center;
}

.admin_calendar {
  width: 100%;
}

.admin_delete {
  position: absolute;
  right: 2%;
  top: 30%;
  cursor: pointer;
}

@media (max-width: 900px) {
  .calendar {
    margin-bottom: 0;
    border-bottom: 1px solid rgb(197, 197, 197);
  }

  .home_calendar {
    margin-bottom: 15px;
  }
}
