.article {
  padding-top: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* article cards */

.article_main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_articles {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
}

.article_list_map {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
  margin: 1%;
  margin-bottom: 20px;
  overflow: hidden;
  border-bottom: 1px solid rgb(189, 189, 189);
  padding: 0.5%;
  transition: 1s;
  position: relative;

  img {
    border-radius: 5px;
    transition: 0.2s;
    height: 300px;
    width: 500px;
    top: 0;
    opacity: 0.9;
    object-fit: cover;
  }

  h2 {
    font-size: 1.5rem;

    &:hover {
      opacity: 0.8;
    }
  }

  &:hover {
    box-shadow: 1px 1px 5px grey;
    transition: 1s;
    background: rgb(250, 250, 250);
    border-radius: 5px;
  }
}

.alm_content {
  background: #fff;
  margin: 2%;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  min-width: 95%;
  transition: 1s;
}

.alm_btm {
  display: flex;
  justify-content: space-between;
}

.alm_btm p:nth-of-type(1) {
  color: #b67a46;
}

.article_list_map:hover .alm_content {
  background: rgb(250, 250, 250);
  transition: 1s;
}

/* ---- */

.article_top_map {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-article-wrapper {
  width: 95%;
  margin: auto;
  margin-bottom: 50px;

  .admin-pagination {
    display: flex;
    margin: auto;
    margin-top: 30px;

    .paginated-active {
      border-bottom: 1px solid #232f3e;
    }

    div {
      margin: 5px 20px;
      color: #232f3e;
      cursor: pointer;
    }
    button {
      border: 0;
      color: #232f3e;
      cursor: pointer;
    }

    button:disabled {
      cursor: auto;
      opacity: 0.5;
    }
  }
}

.admin-article-map {
  margin-left: 20px;
  flex-direction: row;
  margin: auto;
  width: 80%;
  justify-content: space-between;
  border-bottom: 1px solid #c7c7c7;

  button {
    border: 0;
    border-left: 1px solid #c7c7c7;
    cursor: pointer;
  }
  button:hover {
    transform: scale(1.05);
    opacity: 0.7;
  }
}

.article a,
.article_top_map a {
  display: inline-block;
  color: rgb(82, 82, 82);
  text-decoration: none;
}

.article_top_map h2 {
  color: #525252;
  font-size: 1rem;
  display: list-item;
  list-style-image: url('https://image.flaticon.com/icons/svg/171/171322.svg');
}

.article_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.article_search {
  width: 500px;
  height: 30px;
  border: 1px solid grey;
  border-bottom: 2px solid #000;
  font-size: 1.5rem;
  margin-bottom: 5%;
}

.article_search:focus {
  outline: none;
}

@media (max-width: 1041px) {
  .article_list_map,
  .article_list_map img {
    width: 425px;
  }

  .article_list_map img {
    height: 275px;
  }
}

@media (max-width: 886px) {
  .article_list_map,
  .article_list_map img {
    width: 600px;
  }

  .article_list_map img {
    height: 300px;
  }

  .alm_content {
    min-height: inherit;
  }
}

@media (max-width: 650px) {
  .article_list_map {
    width: 90%;
  }
  .article_list_map img {
    height: 250px;
    width: 98%;
    margin: 2px auto;
  }

  .alm_content p {
    font-size: 0.8rem;
  }

  .article_search {
    width: 400px;
  }

  .article_list_map h2 {
    font-size: 0.8rem;
  }
}

@media (max-width: 430px) {
  .article_list_map {
  }
  .article_list_map img {
    height: 200px;
  }

  .article_search {
    width: 300px;
  }
  .alm_content p {
    font-size: 0.6rem;
  }

  .article_list_map h2 {
    font-size: 0.8rem;
  }

  .article h1 {
    font-size: 1.8rem;
  }
}

.svg {
  height: 40px;
}
