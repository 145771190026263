.admin,
.admin1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  padding-top: 60px;
  position: relative;
}

.admin1 {
  background: url('https://wallpapertag.com/wallpaper/full/0/b/a/371175-free-download-blurry-background-3840x2160-lockscreen.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.admin_main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.admin_login {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3%;
  height: 60vh;
  box-shadow: 1px 1px 5px grey;
  background: #fff;
}

.admin_login > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.admin_login input {
  margin: 1%;
  background: #fff;
  height: 20px;
}
.admin_login input:focus {
  outline: none;
}

.admin_login p {
  color: #343434;
  margin-bottom: 0;
  font-size: 0.6em;
  letter-spacing: 1.5px;
}

.admin-article-body {
  display: flex;
  flex-direction: column;
}

.admin-top-container {
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.admin_login h3 {
  font-weight: 200;
  padding: 2%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  background: #232f3e;
  color: #fff;
}
.admin_login h3:hover {
  transform: scale(1.005);
}
.admin_login h3:active {
  transform: scale(0.98);
}

.invalid {
  color: red !important;
}

.admin_main_sub {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin_main_sub > div {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.admin_main_sub2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin h1 {
  font-size: 2.5rem;
}

.events_input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-shadow: 1px 1px 5px rgb(175, 175, 175);
}

.events_input2 h2 {
  font-size: 1.2em;
}

.ce_title {
  margin-top: 5%;
}

.events_input > div {
  display: flex;
  flex-direction: column;
  padding: 2%;
}

.events_input h3 {
  align-self: center;
  cursor: pointer;
}

.events_input h3:hover {
  transform: scale(1.02);
}

.events_input h3:active {
  transform: scale(0.98);
}

.events_input input:focus {
  outline: none;
  background: #f2f2f2;
}

.admin_link {
  color: rgb(56, 56, 56);
  text-decoration: none;
}

.admin_link:hover {
  transform: scale(1.02);
}

.admin_link:active {
  transform: scale(0.98);
}

.calendar_view {
  border-bottom: 1px solid rgb(214, 214, 214);
  padding-bottom: 1%;
}

.admin_map {
  width: 100%;
}

.requests_map {
  border-bottom: 2px solid #5e5e5e52;
  box-shadow: 1px 1px 10px -2px #5e5e5e52;
  width: 100%;
  padding: 1%;
  margin-bottom: 1%;
  position: relative;
}

.form_del {
  position: absolute;
  top: 0;
  right: 3%;
  cursor: pointer;
}

.requests_map h5 {
  letter-spacing: 1px;
  border-bottom: 1px solid rgb(190, 190, 190);
  margin: 5% 0;
  width: 100%;
}

.admin_sub1 {
  width: 30%;
  margin-bottom: 3%;
}

.admin_sub2 {
  width: 50%;
  margin-bottom: 3%;
}

.admin_sub3 {
  width: 100%;
}

.admin_calendar {
  width: 100%;
}

.admin_title {
  text-align: center;
}

.admin_links {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 4%;
  cursor: pointer;
  opacity: 0.6;
}

.admin_side {
  color: #d4d4d4;
  height: 40vh !important;
  align-items: flex-start !important;

  a {
    text-decoration: none;
    color: inherit;
    margin: 0 !important;
  }

  h4:hover, 
  a:hover {
    color: #fff;
    cursor: pointer;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 70%;
    margin-left: 3%;

    img {
      height: 30px;
      color: #fff;
    }
  }

}

.admin_links a:hover {
  transform: scale(1.02)  
}

.admin_main nav {
  width: 100vw;
  text-align: center;
  position: relative;
}

#admin1 {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 10%;
  left: 3%;
  z-index: 999;
}

#admin1:hover {
  cursor: pointer;
}

#admin1 .burger{
  background: #232F3E;
  border-color: #232F3E;
  height: 2px;
}

#admin1:hover .burger{
  opacity: .6;
}

.recurring_checkbox {
  
}