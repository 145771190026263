.contact {
  display: flex;
  justify-content: space-between;
  padding-top: 6%;
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

.speaker_name {
  text-align: center;
  font-size: 2rem;
  letter-spacing: 2px;
  color: rgb(60, 60, 60);
}

.contact_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
}

.contact_sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 0;
  width: 100%;
}

.request_form {
  width: 50%;
  padding: 2%;
  margin: 5% 0;
  margin-left: 10%;
}

.request_form h3 {
  text-align: center;
  color: rgb(58, 58, 58);
  font-weight: 300;
}

.btn_box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #232f3e;
  width: 200px;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
}

.contact_form {
  text-decoration: none;
  color: rgb(238, 238, 238);
}

.contact_form:hover {
  transform: scale(1.01);
}

.contact_form:active {
  transform: scale(0.98);
}

.contact_sub > div {
  display: flex;
  justify-content: space-around;
}

.ci_holder {
  width: 400px;
  height: 500px;
  right: 0;
  opacity: 0.9;
}

.contact_img {
  height: 100%;
  width: 100%;
}

.form_pic_btm {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 980px) {
  .contact_1 {
    width: 100%;
  }
}

@media (max-width: 875px) {
  .form_pic_btm {
    flex-direction: column;
    align-items: center;
  }

  .contact_sub {
    margin-left: 0;
    width: 100%;
  }

  .ci_holder {
    width: auto;
    height: auto;
    margin: 0 10%;
  }
}

@media (max-width: 700px) {
  .speaker_name {
    font-size: 1.5rem;
  }

  .request_form h3 {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .speaker_name {
    font-size: 1rem;
    margin-top: 10%;
  }

  .form_pic_btm h3 {
    font-size: 0.8rem;
    margin: 5% 0 10% 0;
  }

  .ci_holder {
    margin: 0;
  }
}
